import { useSelector } from 'app/hooks/store/use-selector.hook';

export interface LoadsSearchState {
  performedSuggestedLoadSearch: boolean;
  searchCriteria: AvailableLoadSearchCriteriaJSON;
  searchCorrelationId: string;
}

export const useLoadsSearchState = (): LoadsSearchState => {
  return useSelector<LoadsSearchState>(
    state => ({
      performedSuggestedLoadSearch: state.findLoads.results.performedSuggestedLoadSearch,
      searchCriteria: state.findLoads.searchCriteria,
      searchCorrelationId: state.analytics?.search.searchCorrelationId,
    })
  );
};
